<template>
   <div class="content">
    <div class="pt-4" style="padding-top:40px;padding-left:40px">
      <el-breadcrumb separator="/">
          <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
          <el-breadcrumb-item><a href="/">用户管理</a></el-breadcrumb-item>
        </el-breadcrumb>
        <div class="mt-4">
             <el-form :inline="true" :model="searchInfo" class="search-area scrch-style">
                    <el-form-item label="用户属性"  label-width="100px">
                          <el-select clearable v-model="searchInfo.userType" placeholder="请选择" @change="typechane">
                                <el-option value="buyer" label="B端买手"></el-option>
                                <el-option value="cUser" label="客户"></el-option>
                                <el-option value="cbuyer" label="C端买手"></el-option>
                            </el-select>
                    </el-form-item>
                    <el-form-item label="微信昵称"  label-width="100px" v-show="searchInfo.userType == 'cUser'">
                         <el-input v-model="searchInfo.wxNick"></el-input>
                    </el-form-item>
                    <el-form-item label="微信号"  label-width="100px"  v-show="searchInfo.userType == 'cUser'">
                         <el-input v-model="searchInfo.wxNumber"></el-input>
                    </el-form-item>
                    <el-button type="danger" @click="search" class="" >查询</el-button>
            </el-form>
             <el-table
                class="table-style"
                    :data="tableData"
                    v-show="searchInfo.userType == 'buyer'"
                    header-align="center"
                    height="500"
                    style="width: 100%">
                    <!-- <el-table-column
                    align="center"
                      prop="date"
                      label="日期"
                      >
                    </el-table-column> -->
                    <el-table-column
                    align="center"
                      prop="topNo"
                      sortable

                      label="买手编号"
                      >
                    </el-table-column>
                     <el-table-column
                    align="center"
                      prop="userName"
                      sortable
                      label="买手名称"
                      >
                    </el-table-column>
                    <el-table-column
                    align="center"
                      prop="wxImg"
                      sortable
                      label="买手头像"
                      >
                      <template slot-scope="scope">
                        <div>
                          <img :src="scope.row.wxImg" alt="" srcset="" style="width:50px;height:50px">
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                    align="center"
                      prop="wxNick"
                      sortable
                      label="微信昵称"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      sortable
                      prop="wxNumber"
                      label="微信号"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="userLevel"
                      sortable
                      label="职级">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="phone"
                      sortable
                      label="手机号">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="acl"
                      sortable
                      label="用户权限">
                      <template slot-scope="scope">
                        <div>
                          {{scope.row.acl =='admin' ? '管理员' :'普通买手' }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      fixed="right"
                      label="操作"
                      width="120">
                      <template slot-scope="scope">
                        <div @click="edit(scope.row,'3')" class="updateBtn">
                          离职
                        </div>
                        <div @click="edit(scope.row,'1')" class="updateBtn">
                          编辑
                        </div>
                      </template>
                    </el-table-column>
            </el-table>
              <el-table
                class="table-style"
                    :data="tableData"
                    v-show="searchInfo.userType == 'cUser'"
                    header-align="center"
                    height="500"
                    style="width: 100%">
                    <!-- <el-table-column
                    align="center"
                      prop="date"
                      label="日期"
                      >
                    </el-table-column> -->
                    <el-table-column
                    align="center"
                      prop="gmtCreate"
                      sortable
                      width="150"
                      label="日期"
                      >
                    </el-table-column>
                     <el-table-column
                    align="center"
                      prop="wxNick"
                      width="150"
                      label="微信昵称"
                      >
                    </el-table-column>
                    <el-table-column
                    align="center"
                      prop="wxImg"
                      width="100"
                      label="客户头像"
                      >
                      <template slot-scope="scope">
                        <div>
                          <img :src="scope.row.wxImg" alt="" srcset="" style="width:50px;height:50px">
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="platform"
                       width="100"
                      label="卖货平台"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                       width="100"
                      prop="fansNumber"
                      label="粉丝数"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="memberStatus"
                       width="100"
                      label="客户状态">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="grade"
                       width="100"
                      label="星级">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="wxNumber"
                       width="100"
                      label="微信号">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="phone"
                       width="100"
                      label="联系方式">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="isOdm"
                       width="120"
                      label="符合ODM标准">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="buyerName"
                       width="100"
                      label="责任买手">
                      
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="source"
                       width="100"
                      label="客户来源">
                    </el-table-column>
                    <el-table-column
                      fixed="right"
                      label="操作"
                      width="120">
                      <template slot-scope="scope">
                        <div @click="edit(scope.row,'2')" class="updateBtn">
                          编辑
                        </div>
                      </template>
                    </el-table-column>
            </el-table>
              <el-table
                class="table-style"
                    :data="tableData"
                    v-show="searchInfo.userType == 'cbuyer'"
                    header-align="center"
                    height="500"
                    style="width: 100%">
                     <el-table-column
                    align="center"
                      prop="wxNick"
                      width="150"
                      label="买手名称"
                      >
                    </el-table-column>
                    <el-table-column
                    align="center"
                      prop="wxImg"
                      width="100"
                      label="买手头像"
                      >
                      <template slot-scope="scope">
                        <div>
                          <img :src="scope.row.wxImg" alt="" srcset="" style="width:50px;height:50px">
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="platform"
                       width="100"
                      label="微信昵称"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                       width="100"
                      prop="fansNumber"
                      label="手机号"
                      >
                    </el-table-column>
                    <el-table-column
                      label="操作"
                      width="120">
                      <template slot-scope="scope">
                        <div @click="edit(scope.row,'2')" class="updateBtn">
                          编辑
                        </div>
                      </template>
                    </el-table-column>
            </el-table>
            <div class="pager-style">
                      <el-pagination layout="total, prev, pager, next"
                                  :total="totalCount"
                                  :page-size="pageSize"
                                  :current-page="curPageNum"
                                  @current-change="handlePagerChange">
                      </el-pagination>
                  </div>
        </div>
    </div>
     <el-dialog :visible.sync="showPop" width="60%" :title="title" :before-close="handleDialogClose" :close-on-click-modal="false">
         <el-form :model="form">
            <div v-if="type == '1'">
                <el-row>
             <el-col :span="12">
                <el-form-item label="姓名" label-width="100px">
                  <el-input v-model="form.userName" style="width: 200px;"></el-input>
                </el-form-item>
             </el-col>
             <el-col :span="12">
               <el-form-item label="手机号" label-width="100px">
                  <el-input v-model="form.phone" style="width: 200px;"></el-input>
                </el-form-item>
             </el-col>
           </el-row>
           <el-row>
             <el-col :span="12">
                <el-form-item label="职级" label-width="100px">
                  <el-input v-model="form.userLevel" style="width: 200px;"></el-input>
                </el-form-item>
             </el-col>
             <el-col :span="12">
               <el-form-item label="买手编号" label-width="100px">
                  <el-input v-model="form.topNo" style="width: 200px;"></el-input>
                </el-form-item>
             </el-col>
           </el-row>
           <el-row>
             <el-col :span="12">
                <el-form-item label="买手权限" label-width="100px">
                    <el-select clearable v-model="form.acl" placeholder="请选择">
                        <el-option value="" label="普通买手"></el-option>
                        <el-option value="admin" label="管理员"></el-option>
                    </el-select>
                </el-form-item>
             </el-col>
           </el-row>
            </div>
             <div v-if="type == '2'">
                <el-row>
                  <el-col :span="12">
                      <!-- <el-form-item label="姓名" label-width="100px">
                        <el-input v-model="form.userName" style="width: 200px;"></el-input>
                      </el-form-item> -->
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="用户权限" label-width="100px">
                       <el-select clearable v-model="userType" placeholder="请选择">
                                <el-option value="cUser" label="客户"></el-option>
                                <el-option value="cbuyer" label="C端买手"></el-option>
                            </el-select>
                      </el-form-item>
                  </el-col>
                </el-row>

            </div>
            <div  v-if="type == '3'">
                 <el-form-item label="交接买手" label-width="100px">
                    <el-select clearable v-model="newBuyerId" placeholder="请选择">
                           <el-option
                              v-for="(item,index) in burUsersList"
                              :key="index"
                              :label="item.user.userName"
                              :value="item.user.userId">
                            </el-option>
                            <!-- <el-option value="cbuyer" label="C端买手"></el-option> -->
                      </el-select>
                  </el-form-item>
            </div>
        </el-form>
         <el-row>
          <el-col>
            <el-button type="danger" class="fr" style="margin-top:40px" @click="handleDialog" >修改</el-button>
             <el-button type="danger" class="fr" style="margin-top:40px;margin-right:40px" @click="handleDialogClose" >取消</el-button>
          </el-col>
        </el-row>
     </el-dialog>
  </div>
</template>
<script>
export default {
  data(){
    return{
      attribute:'1',
      totalCount:0,
      pageSize:10,
      curPageNum:1,
      showPop:false,
      title:'',
      userType:'',
      form:{
        wxName:'',
        phone:'',
        isImportant:''
      },
      searchInfo:{
        userType:'buyer',
        wxNick:'',
        wxNumber:''
      },
      newBuyerId:'',
      type:'',
      tableData:[],
    }
  },
  created(){
    this.getData()
    this.getBuyerUsers()
  },
  methods:{
    typechane(){
      this.curPageNum = 1
      this.getData()
    },
    edit(value,type){
      this.form = JSON.parse(JSON.stringify(value))
      this.type = type
      this.showPop = true
      if(type == '1'){
        // if(!this.form.act){
        //   this.form.acl = ''
        // } else{
        //   this.form.act = 'admin'
        // }
        console.log(this.form)
        this.title = '修改b端买手'
      }
      if(type == '2'){
        this.title = '新增用户属性'
      }
      if(type == '3'){
        this.title = '离职'
      }
    },
    handleDialog(){
      let params = {}
      if(this.type == '1'){
        if(this.form.phone != ''){
          //   if(!(/^1[3456789]d{9}$/.test(this.form.phone))){ 
          //     alert("手机号码有误，请重填");  
          //     return false; 
          // } 
        }
        
        // return false
        this.$post('/userManage/updateBUserManage',this.form).then(res =>{
            if(res.code == '200'){
              this.$message.success('修改成功')
              this.getData()
              this.handleDialogClose()
            }
        })
      } else if(this.type == '2'){
         params.userType = this.userType
         params.userId = this.form.userId
         this.$post('/userManage/updateCUserToCBuyer',params).then(res =>{
            if(res.code == '200'){
              this.$message.success('修改成功')
              this.getData()
              this.handleDialogClose()
            }
        })
      } else {
        let params = {oldBuyerId:this.form.userId,newBuyerId:this.newBuyerId}
         this.$post('/userManage/updateOBuyerToNBuyer',params).then(res =>{
            if(res.code == '200'){
              this.$message.success('修改成功')
              this.getData()
              this.handleDialogClose()
            }
        })
      }
    },
    handleDialogClose(){
      this.type = ''
      this.title = ''
      this.showPop = false
    },
    getData(){
      let params ={
        userType:this.searchInfo.userType,
        wxNick:this.searchInfo.wxNick,
        wxNumber:this.searchInfo.wxNumber,
        pageSize:this.pageSize,
        pageIndex:this.curPageNum
      }
      if(this.searchInfo.userType != 'cUser'){
        // Vue.delete()
        delete params.wxNumber
        delete params.wxNick
      }
      this.$post(`/userManage/queryUserManageList`,params).then(res =>{
        console.log(res)
        this.tableData = res.data.list
        this.totalCount = res.totalCount
      })
    },
    getBuyerUsers(){
        this.$post(`/userManage/getBuyerUsers`,{}).then(res =>{
            this.burUsersList = res.data.list
        })
    },
    handlePagerChange(value){
        this.curPageNum = value
        this.getData()
      },
      search(){
        this.curPageNum = 1
        if(this.searchInfo.userType != 'cUser'){
          this.searchInfo.wxNick = ''
          this.searchInfo.wxNumber = ''
        }
        this.getData()
      }
  }
}
</script>
<style lang="less" rel="stylesheet/less" scoped>
</style>
